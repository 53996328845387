// Footer
//

.footer-link {
  display: inline-block;
}

// Dark footer
.footer-dark {
  color: $navbar-dark-color;

  .footer-link {
    color: $navbar-dark-color;

    @include hover-focus {
      color: $navbar-dark-hover-color;
    }

    &.disabled {
      color: $navbar-dark-disabled-color !important;
    }
  }

  .footer-text {
    color: $navbar-dark-active-color;
  }

  .show > .footer-link,
  .active > .footer-link,
  .footer-link.show,
  .footer-link.active {
    color: $navbar-dark-active-color;
  }

  hr {
    border-color: $sidenav-dark-border-color;
  }
}

// Light footer
.footer-light {
  color: $navbar-light-color;

  .footer-link {
    color: $navbar-light-color;

    @include hover-focus {
      color: $navbar-light-hover-color;
    }

    &.disabled {
      color: $navbar-light-disabled-color !important;
    }
  }

  .footer-text {
    color: $navbar-light-active-color;
  }

  .show > .footer-link,
  .active > .footer-link,
  .footer-link.show,
  .footer-link.active {
    color: $navbar-light-active-color;
  }

  hr {
    border-color: $sidenav-light-border-color;
  }
}
